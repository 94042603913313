import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
// import { SessionStorageService } from '../../../../SGRE-shared/services/session-storage.service';
import { MyProfileService } from '../../services/my-profile.service';
import { Observable, Subscription } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { LanguageService } from '@spartacus/core';

@Component({
  selector: 'app-my-profile-detail',
  templateUrl: './my-profile-detail.component.html',
  styleUrl: './my-profile-detail.component.scss'
})
export class MyProfileDetailComponent implements OnInit {

  constructor(
    // private sessionService: SessionStorageService,
    private myProfileService: MyProfileService,
    private changeRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public storageService: StorageService,
    private globalService: GlobalService,
    private languageService: LanguageService
  ) { 
  }

  public selectedDate: string = 'American';
  public data: {} = {};
  public isEdit: boolean = false;
  public toggleRequest: boolean = false;
  public role: string = '';
  public visible: boolean = false;
  public modalpopup: boolean;
  public comment: string = '';
  public userData: any;
  myProfileForm: FormGroup;
  submitted: boolean = false;
  private dataSubscripion: Subscription;
  public receivedData: any[] = [];
  public toasterSuccess: boolean = false;
  public userLoggedIn: Observable<any>;
  public currentLanguage: any = 'English';
  updatedCutomers: any[] = [];
  public selectedDateFormat: string = '';
  public selectedLanguage: string = '';
  ngOnInit() {
    this.selectedDateFormat = this.storageService.dateFormat;
    this.selectedLanguage = this.storageService.selectedLanguage;
    this.languageService.getActive().subscribe((lang) => {
      this.currentLanguage = lang
    })
    this.getFromSessionStorage();
    this.myProfileFormInitialise();
    this.userLoggedIn = this.globalService.loginBanner$
    this.myProfileService.myProfileData$.subscribe(
      (data) => {
        this.toggleRequest = false
        this.userData = data;
        this.updateValues();
        this.changeRef.detectChanges();
      }
    );
    this.dataSubscripion = this.myProfileService.currentData.subscribe(data => {
      this.receivedData = data;
    })
    this.getTableData();
  }

  getTableData() {
    this.myProfileService.profileData$.subscribe((data) => {
      this.updatedCutomers = data;
      this.changeRef.detectChanges();
    });
  }

  myProfileFormInitialise() {
    this.myProfileForm = this.fb.group({
      language: [this.returnCurrentSelector(this.currentLanguage) ? this.returnCurrentSelector(this.currentLanguage) : 'English'],
      dateFormat: [this.storageService.dateFormat ? this.returnCurrentDateSelector() : 'American'],
      name: [''],
      email: [''],
      alternativeEmail: [''],
    });
  }

  updateValues() {
    this.myProfileForm.patchValue({
      alternativeEmail: this.userData.alternateEmail,
    });
  }

  onSubmitEmail() {
    this.submitted = true;
    if (this.myProfileForm.value.alternativeEmail != '') {
      this.userData.alternateEmail = this.myProfileForm.value?.alternativeEmail;
      this.myProfileService.updateAlternativeEmail(this.userData.alternateEmail).subscribe({
        next: (value: any) => {
        },
        error: err => console.error('Observable emitted an error: ' + err)

      });
    }
  }

  selectChangeHandler(event: any) {
    this.selectedDate = event.target.value;
    if (this.selectedDate === 'European Latin 1') {
      this.storageService.dateFormat = 'dd-MM-yyyy';
      let langDateObj= {dateFormat:'dd-MM-yyyy'}
      this.languageDateChange(langDateObj)
    }
    else if (this.selectedDate === 'American') {
      this.storageService.dateFormat = 'MM-dd-yyyy';
      let langDateObj= {dateFormat:'MM-dd-yyyy'}
      this.languageDateChange(langDateObj)
    }
    else {
      this.storageService.dateFormat = 'yyyy-MM-dd';
      let langDateObj= {dateFormat:'yyyy-MM-dd'}
      this.languageDateChange(langDateObj)
    }
  }

  selectLanguageCHandler(event: any) {
    if (event.target.value == 'German (Germany)') {
      this.languageService.setActive('de');
      this.globalService.setLanguage('GER');
      this.storageService.selectedLanguage = 'de';
      let langDateObj={languageISOCode:'de'}
      this.languageDateChange(langDateObj)
    }
    else if (event.target.value == 'Spanish (Spain)') {
      this.languageService.setActive('es');
      this.globalService.setLanguage('ESP');
      this.storageService.selectedLanguage = 'es';
      let langDateObj={languageISOCode:'es'}
      this.languageDateChange(langDateObj)
    }
    else {
      this.languageService.setActive('en')
      this.globalService.setLanguage('ENG')
      this.storageService.selectedLanguage = 'en';
      let langDateObj={languageISOCode:'en'}
      this.languageDateChange(langDateObj)
    }
  }

  languageDateChange(langDateObj){
    let req1 =langDateObj?.dateFormat == undefined ?{languageISOCode:langDateObj.languageISOCode}:{dateFormat:langDateObj?.dateFormat}
    this.globalService.addLanguageDateChange(req1).subscribe()
  }

  returnCurrentSelector(value) {
    if (value == 'de') {
      return 'German (Germany)'
    }
    else if (value == 'es') {
      return 'Spanish (Spain)'
    }
    else {
      return 'English'
    }
  }

  returnCurrentDateSelector() {
    if (this.storageService.dateFormat === 'dd-MM-yyyy') {
      return 'European Latin 1'
    }
    else if (this.storageService.dateFormat === 'MM-dd-yyyy') {
      return 'American'
    }
    else {
      return 'UTC'
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.myProfileForm.controls;
  }

  toggleEdit(edit?: string) {
    if (edit) {
      this.isEdit = !this.isEdit;
    }
    else {
      this.toggleRequest = !this.toggleRequest
      this.myProfileService.setSelected(this.toggleRequest)
    }
  }

  getFromSessionStorage() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;
    if (userRole?.includes("Requester")) {
      this.role = 'Requester'
    }
    else if (userRole?.includes("Purchaser")) {
      this.role = 'Purchaser'
    }
    else if (userRole?.includes("Customer Admin")) {
      this.role = 'Customer Admin'
    }
    else if (userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }

  sendRequest() {
    this.myProfileService.updateCommentData(this.comment);
    this.comment = '';
  }

  closeRequest(){
    this.comment = '';
  }

  ngOnDestroy() {
    this.dataSubscripion.unsubscribe();
    this.myProfileService.updateCommentData('');
    this.globalService.clearMessagesOnDestroy();
  }
}




